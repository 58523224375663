@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/breakpoints.scss";

.navbar {
  background-color: #fff;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1500px;
    padding: 0 25px;
    position: relative;

    .navLogo {
      width: 160px;
      height: 50px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .navMenu {
      display: flex;
      list-style: none;
      text-align: center;

      .navItem {
        position: relative;
        line-height: 40px;

        &:hover .dropdownMenu {
          display: block;
          padding: 0;
        }

        .navLinks {
          cursor: pointer;
          color: $navbar-color;
          text-decoration: none;
          padding: 0.5rem 1rem;
          border-bottom: 3px solid transparent;
          transition: all 0.3s ease; // Added transition for smooth animation

          &.active {
            color: $navbar-color;
            border-bottom: 3px solid $navbar-color;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: #333;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.3s ease;
          }

          &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }

        .dropdownMenu {
          display: none;
          position: absolute;
          background-color: #eee;
          top: 100%;
          left: 0;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          list-style-type: none;

          .dropdownLink {
            color: $navbar-color;
            text-decoration: none;
            padding: 0.5rem 1rem;
            display: block;

            &:hover {
              background-color: $navbar-color;
              color: #eee;
            }
          }
        }
      }
    }
    .navIcon {
      display: none;
      color: $navbar-color;
    }
  }
  @include responsive("mdDown") {
    .navContainer {
      padding: 0 15px;
      .navMenu {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        border-top: 1px solid #fff;
        position: absolute;
        top: 70px;
        left: -120%;
        opacity: 1;

        &.active {
          background: #eee;
          left: 0px;
          opacity: 1;
          z-index: 1;
          margin: 0;
          padding: 0;
        }

        .navItem {
          position: relative;

          .navLinks {
            text-align: start;
            display: block;

            &.active {
              color: $navbar-color;
              text-align: left;
              border-bottom: 3px solid $navbar-color; // Animation on active link
            }
          }

          .dropdownMenu {
            display: none;
            position: relative;
            background-color: #eee;
            top: 0;
            left: 0;
            box-shadow: none;

            .dropdownLink {
              padding-left: 2rem;

              &:hover {
                background-color: $navbar-color;
                color: $navbar-color;
              }
            }
          }
        }
      }

      .navIcon {
        display: block;
        font-size: 25px;
        cursor: pointer;
      }
    }
  }

  @include responsive("smDown") {
    .navContainer {
      .navLogo {
        width: 120px;
        height: 40px;
      }
    }
  }
}
