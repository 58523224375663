@import "../../assets/scss/colors.scss";
@import "../../assets/scss/breakpoints.scss";

.product-accordion {
  padding: 20px 20px 40px;
  width: 80%;
  margin: 0 auto;
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    h1 {
      position: relative;
      font-size: 2.5rem;
      color: #333;
      margin: 0;
      padding-bottom: 0.5rem;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #333;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  .accordion {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .accordion__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px;
      background-color: #e2e2e2;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      .accordionTitle {
        font-size: 1.1rem;
        text-transform: capitalize;
        color: #333;
        margin: 0;
      }

      .accordionIcon {
        font-size: 1.2rem;
        color: #333;
        transition: transform 0.3s ease; // Smooth transition for rotation
      }
    }
  }

  [aria-expanded="true"] .accordionIcon {
    transform: rotate(180deg);
  }

  .accordion__panel {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  @include responsive("mdDown") {
    .heading {
      h1 {
        font-size: 1.8rem;
      }
    }
    .accordion {
      .accordion__button {
        .accordionIcon {
          margin-top: 10px;
          font-size: 1rem;
        }
      }
    }
  }
}
