@import "../../assets/scss/colors.scss";
@import "../../assets/scss/breakpoints.scss";

.aboutUsContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid $border-color;

  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    h1 {
      position: relative;
      font-size: 2.5rem;
      color: #333;
      margin: 0;
      padding-bottom: 0.5rem;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #333;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s ease;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }

  .companyImage {
    position: relative;
    padding: 50px;
    border-radius: 10px;
    overflow: hidden;
    background-image: url("../../assets/image/About us.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }

    .aboutSection,
    .missionSection,
    .visionSection,
    .valuesSection {
      background-color: rgba(255, 255, 255, 0.797);
      border-radius: 10px;
      padding: 20px;
      z-index: 5;

      .subtitle {
        font-size: 2rem;
        margin: 0 0 15px;
        text-align: center;
      }

      .description {
        font-size: 1.1rem;
        line-height: 1.6;
        text-align: justify;
      }

      .valuesList {
        list-style-type: none;
        padding: 0;

        li {
          font-size: 1.1rem;
          margin-bottom: 10px;

          strong {
            color: #000;
          }
        }
      }
    }

    .imageSection {
      z-index: 8;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      img {
        width: 32%;
        height: auto;
        margin-bottom: 10px;
      }
    }
  }
}

@include responsive("mdDown") {
  .aboutUsContainer {
    padding: 10px;

    .heading {
      margin-bottom: 30px;
      h1 {
        font-size: 1.8rem;
      }
    }

    .companyImage {
      height: 350px;
      padding: 25px;
      overflow: auto;

      .aboutSection,
      .missionSection,
      .visionSection,
      .valuesSection {
        .subtitle {
          font-size: 1.5rem;
        }
        .description {
          line-height: 1.4;
          font-size: 1rem;
          margin: 0;
        }
        .valuesList li {
          font-size: 1rem;
        }
      }

      .imageSection {
        img {
          width: 48%;
        }
      }
    }
  }

  @include responsive("smDown") {
    .aboutUsContainer {
      .companyImage {
        .imageSection {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
