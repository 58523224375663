///Color
$btn-color: #ca2225;
$navbar-color: #ca2225;
$border-color: #e9503b;

$white-0: #fff;
$white-1: #f8f9fe;
$white-2: #f7fafc;
$white-3: #d9d9d9;
$white-4: #dee2e6;
$white-5: #f0f3f6;
