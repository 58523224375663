@import "../../assets/scss/colors.scss";
@import "../../assets/scss/breakpoints.scss";

.swiper {
  width: 100%;
  height: calc(100vh - 71px);

  .swiper-slide {
    background-position: center;
    background-size: cover;
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 2;
    }

    .subtitle {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: white;
      font-size: 3rem;
      font-weight: bold;
      text-align: center;
      padding: 0 10px;
      width: 100%;

      @media (max-width: 768px) {
        font-size: 2rem;
        padding: 0 5px;
      }

      @media (max-width: 480px) {
        font-size: 1.5rem;
        padding: 0 3px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    font-size: 25px;

    &::after {
      font-size: 25px;
      font-weight: bold;
    }
  }

  @include responsive("mdDown") {
    height: 60vh;

    .swiper-button-prev,
    .swiper-button-next {
      font-size: 20px;

      &::after {
        font-size: 20px;
      }
    }
  }

  @include responsive("smDown") {
    height: 50vh;

    .swiper-button-prev,
    .swiper-button-next {
      font-size: 18px;

      &::after {
        font-size: 18px;
      }
    }
  }
}
