@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/breakpoints.scss";

.footer {
  background-color: #333;
  padding: 20px;
  color: #fff;

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 15px;
  }
    .info,
    .links,
    .contact,
    .address {
    flex: 1;
    margin: 10px;

    .logo {
      width: 260px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .sectionTitle {
      font-size: 1.2em;
      margin-bottom: 10px;
    }
    a {
      color: white;
      text-decoration: none;
    }

    .socialMedia {
      margin-top: 20px;
      display: flex;
      gap: 12px;
      font-size: 26px;

      i {
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #0056b3;
        }
      }
    }
    .linkList {
      list-style-type: none;
      padding: 0;

      li {
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: white;
        }

        a:hover {
          text-decoration: underline;
          color: #74787c;
        }
      }
    }

    .view-all {
      display: block;
      margin-top: 10px;
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bottom {
    text-align: center;
    border-top: 1px solid #74787c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    p {
      margin: 0;
    }

    .terms {
      display: inline-block;
      // margin-top: 10px;
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  @include responsive("smDown") {
    padding: 10px;
    .content {
      padding-bottom: 0px;
    }
    .info,
    .links,
    .contact,
    .address {
      .logo {
        width: 200px;
        height: 60px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .bottom {
      padding: 7px 0;
      margin-top: 10px;
      flex-direction: column;
    }
  }
}
