@import "../../../assets/scss/colors.scss";
@import "../../../assets/scss/breakpoints.scss";

.contactInfo {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $navbar-color; // Background color for the contact header
  max-width: 1500px;
  padding: 0 30px;
  color: #ffff;

  .contactDetails {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 30px;

    li {
      // color: #fff;
      font-size: 1rem;

      a {
        color: #fff;
        font-size: 1rem;
        text-decoration: none;
        // font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    @include responsive("mdDown") {
      gap: 15px;
      li {
        font-size: 0.8rem; // Adjust font size for tablets
        a {
          font-size: 0.8rem; 
        }
      }
    }
    @include responsive("smDown") {
      flex-direction: column;
      gap: 5px;
    }
  }

  .socialMedia {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;

    li {
      a {
        font-size: 1.4rem;
        color: #fff;

        &:hover {
          color: #007bff;
        }
      }
    }
    @include responsive("mdDown") {
      gap: 15px;
      li {
        a {
          font-size: 1.3rem;
        }
      }
    }
    @include responsive("smDown") {
      gap: 10px;
      li {
        a {
          font-size: 1.1rem;
        }
      }
    }
  }
}
