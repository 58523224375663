@import "../../assets/scss/colors.scss";
@import "../../assets/scss/breakpoints.scss";

.home {
  .homeContainer {
    min-height: calc(100vh - 151px);
    position: relative;
    z-index: 1;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: calc(100vh - 151px);
      opacity: 0.4;
      z-index: -1;
      background-color: black;
    }
    @include responsive("mdDown") {
      min-height: auto;
      &::before {
        min-height: auto;
      }
    }
  }
  .productServiceContainer {
    padding: 20px 20px 35px;
    max-width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid $border-color;
    .productContent {
      font-size: 18px;
    }
    .exploreProduct {
      padding: 10px 20px;
      background-color: $btn-color;
      color: white;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }
  }
}
